<template>
  <div>
    <v-form
      :lazy-validation="true"
      @submit.prevent="handleSubmit"
      v-model="valid"
      ref="form"
    >
      <v-card>
        <UserRegistrationInputs v-on:update-user="updateUserInformation" />

        <v-card-text>
          <v-btn color="primary" type="submit" x-large>{{
            $t("common.add")
          }}</v-btn>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import UserRegistrationInputs from "@/components/user/UserRegistrationInputs";
import { mapActions } from "vuex";

export default {
  name: "UserCreate",
  data() {
    return {
      valid: false,
      userInput: {},
    };
  },

  methods: {
    ...mapActions("companies", ["createUser"]),

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      await this.createUser(this.userInput);
      this.$router.push("/company");
    },

    updateUserInformation(user) {
      this.userInput = user;
    },
  },

  components: {
    UserRegistrationInputs,
  },
};
</script>

<style>
</style>